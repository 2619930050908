$sidercolor: #1b203d;
$bgcolor:  #1b203a;
$cardBg: #272b4a;

@font-face {
    font-family: 'Kostic_Roc_Grotesk_Wide_Bold';
    src: url(./assets/fonts/Kostic_Roc_Grotesk_Wide_Bold.otf)
}

@font-face{
    font-family: 'Kostic_Roc_Grotesk_Regular';
    src: url(./assets/fonts/Kostic_Roc_Grotesk_Regular.otf);
}

@font-face{
    font-family: 'Kostic_Roc_Grotesk_Bold';
    src: url(./assets/fonts/Kostic_Roc_Grotesk_Bold.otf);
}